import React from 'react';

const About = ({ basics }) => (
  <section id="about" className="row mt-m">
    <div>
      <h3>ABOUT</h3>
    </div>
    <div className="col-sm-12">
      <p>{basics.summary}</p>
    </div>
  </section>
);

export default About;
