import React from 'react';

const Contact = ({ basics }) => (
  <section id="contact" className="row">
    <div>
      <h3>Contact</h3>
    </div>
    <div className="col-sm-9">
      <div className="row">
        <div className="col-sm-6">
          <strong>Email</strong>
          <br></br>
          <a href={`mailto:${basics.email}`} className="email">{basics.email}</a>
        </div>
        {/* <div className="col-sm-6">
          <strong>Phone</strong>
          <br></br>
          <a href={`tel:${basics.phone}`} className="phone">{basics.phone}</a>
        </div> */}
        <div className="col-sm-6">
          <strong>Website</strong>
          <div className="website">
            <a target="_blank" rel="noreferrer" href={basics.url}>{basics.url}</a>
          </div>
        </div>
        <div className="col-sm-6">
          <strong>LinkedIn</strong>
          <div className="website">
            <a target="_blank" rel="noreferrer" href={basics.linkedIn}>{basics.linkedIn}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
