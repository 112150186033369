import React from 'react';

const Education = ({ educations }) => (
  <section id="education" className="row">
    <div className='mt-m'>
      <h3>Education</h3>
    </div>
    <div className="col-sm-12">
      <div className="row">
        {educations.map(education => (
          <div className="col-sm-12">
            <h4 className="strike-through">
              <span>{education.institution}</span>
              <span className="date">
                {education.startDate} — {education.endDate}
              </span>
            </h4>
            <div className="area">
              {education.area}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>);

export default Education;
