import React from 'react';

const Skills = ({ skills }) => (
  <section id="skills" className="row mt-m">
    <div>
      <h3>FUNCTIONAL EXPERTISE</h3>
    </div>
    <div className="col-sm-12">
      <div className="row">
        {skills.map(skill => (
          <div className="col-sm-6">
            <ul className="keywords">
              {skill.map(s => (
                <li>{s}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </section>);

export default Skills;
