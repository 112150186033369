import React from 'react';
import { graphql } from 'gatsby';

import Resume from '../components/resume';

const Page = ({ data }) => {
  const resume = data.resumeYaml;
  return <Resume resume={resume} />;
};

export default Page;

export const query = graphql`
  query MyQuery {
    resumeYaml {
      basics {
        email
        name
        label
        phone
        url
        summary
        image
        linkedIn
      }
      skills 
      work {
        description
        location
        name
        isWorking
        startDate(formatString: "MMM, YYYY")
        endDate(formatString: "MMM, YYYY")
        positions {
          title
          startDate(formatString: "MMM, YYYY")
          endDate(formatString: "MMM, YYYY")
          isWorking
          details
        }
        url
      }

      education {
        area
        courses
        endDate(formatString: "MMM, YYYY")
        gpa
        institution
        startDate(formatString: "MMM, YYYY")
      }
      awards {
        awarder
        date(formatString: "MMM, YYYY")
        title
        link
      }
      languages {
        fluency
        language
      }
      interests
    }
  }
`;
