import React from 'react';

const Interests = ({ interests }) => (
	<section id="interests" className="row">
		<div className='mt-m'>
			<h3>Interests</h3>
		</div>
		<div className="col-sm-12">
			<div className="row">
			{interests.map(interest => (
        <div className="col-sm-6">
          <div className="name">
            <p>{interest}</p>
          </div>
        </div>
      ))}
			</div>
		</div>
	</section>);

export default Interests;
