import React from 'react';

const Work = ({ works }) => (
  <section id="work" className="row mt-m">
    <div >
      <h3>FUNCTIONAL EXPERTISE DEMONSTRATED</h3>
    </div>
    <div className="col-sm-12">
      <div className="row">
        {works.map(work => (
          <div className="col-sm-12">
            <h4 className="strike-through">
              <span>{work.name}</span>
              <span className="date">
                {work.startDate} — {work.isWorking ? 'Present' : work.endDate}
              </span>
            </h4>
            <div className="website pull-right">
              <a href={work.url}>{work.url}</a>
            </div>
            <i className="description">{work.description}</i>
            {/* <div className="position mt-xs">{work.position}</div> */}

            <div>
              {work.positions.map(position => (
                <div>
                  <div className='mt-s position'>
                    <span>{position.title}</span>
                    <span className="date ml-m ft-s">
                      {position.startDate} — {position.isWorking ? 'Present' : position.endDate}
                    </span>
                  </div>
                  <div>
                    {position.details && (
                      <React.Fragment>
                        <ul className="highlights">
                          {position.details.map(highlight => (
                            <li className="bullet">{highlight}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                </div>

              ))}
            </div>



          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Work;
