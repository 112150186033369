import React from 'react';

const Awards = ({ awards }) => (
  <section id="awards" className="row">
    <div className='mt-m'>
      <h3>Awards</h3>
    </div>
    <div className="col-sm-12">
      <div className="row">
        {awards.map(award => (
          <div className="col-sm-12">
            <h4 className="strike-through">
              <span>{award.title}</span>
            </h4>
            <div className="date">
              <em>Awarded </em>
              {award.date}
              <span className="awarder">
                <em> by </em>
                <strong><a href={award.link} target="_blank" rel="noreferrer">{award.awarder}</a></strong>
              </span>
            </div>

          </div>
        ))}
      </div>
    </div>
  </section>);

export default Awards;
